export const DOCUMENTS_LOAD_START = 'DOCUMENTS_LOAD_START';
export const DOCUMENTS_LOAD_FINISH = 'DOCUMENTS_LOAD_FINISH';
export const DOCUMENTS_LOAD_ERROR = 'DOCUMENTS_LOAD_ERROR';

export const DOCUMENTS_SILENT_LOAD_START = 'DOCUMENTS_SILENT_LOAD_START';
export const DOCUMENTS_SILENT_LOAD_FINISH = 'DOCUMENTS_SILENT_LOAD_FINISH';
export const DOCUMENTS_SILENT_LOAD_ERROR = 'DOCUMENTS_SILENT_LOAD_ERROR';

export const DOCUMENT_LOAD_START = 'DOCUMENT_LOAD_START';
export const DOCUMENT_LOAD_FINISH = 'DOCUMENT_LOAD_FINISH';
export const DOCUMENT_LOAD_ERROR = 'DOCUMENT_LOAD_ERROR';

export const DOCUMENT_CREATE_START = 'DOCUMENT_CREATE_START';
export const DOCUMENT_CREATE_ERROR = 'DOCUMENT_CREATE_ERROR';
export const DOCUMENT_CREATE_FINISH = 'DOCUMENT_CREATE_FINISH';

export const DOCUMENT_DELETE_START = 'DOCUMENT_DELETE_START';
export const DOCUMENT_DELETE_ERROR = 'DOCUMENT_DELETE_ERROR';
export const DOCUMENT_DELETE_FINISH = 'DOCUMENT_DELETE_FINISH';

export const DOCUMENT_UPDATE_START = 'DOCUMENT_UPDATE_START';
export const DOCUMENT_UPDATE_FINISH = 'DOCUMENT_UPDATE_FINISH';
export const DOCUMENT_UPDATE_ERROR = 'DOCUMENT_UPDATE_ERROR';

export const DOCUMENT_TYPE_START = 'DOCUMENT_TYPE_START';
export const DOCUMENT_TYPE_FINISH = 'DOCUMENT_TYPE_FINISH';
export const DOCUMENT_TYPE_ERROR = 'DOCUMENT_TYPE_ERROR';

export const DOCUMENT_UPDATE_SILENT_START = 'DOCUMENT_UPDATE_SILENT_START';
export const DOCUMENT_UPDATE_SILENT_FINISH = 'DOCUMENT_UPDATE_SILENT_FINISH';
export const DOCUMENT_UPDATE_SILENT_ERROR = 'DOCUMENT_UPDATE_SILENT_ERROR';

export const DOCUMENT_HISTORY_LOAD_START = 'DOCUMENT_HISTORY_LOAD_START';
export const DOCUMENT_HISTORY_LOAD_FINISH = 'DOCUMENT_HISTORY_LOAD_FINISH';
export const DOCUMENT_HISTORY_LOAD_ERROR = 'DOCUMENT_HISTORY_LOAD_ERROR';

export const DOCUMENT_LOCK_SET_START = 'DOCUMENT_LOCK_SET_START';
export const DOCUMENT_LOCK_SET_FINISH = 'DOCUMENT_LOCK_SET_FINISH';
export const DOCUMENT_LOCK_SET_ERROR = 'DOCUMENT_LOCK_SET_ERROR';

export const DOCUMENT_LOCK_REMOVE_START = 'DOCUMENT_LOCK_REMOVE_START';
export const DOCUMENT_LOCK_REMOVE_FINISH = 'DOCUMENT_LOCK_REMOVE_FINISH';
export const DOCUMENT_LOCK_REMOVE_ERROR = 'DOCUMENT_LOCK_REMOVE_ERROR';

export const DOCUMENT_LOAD_USER_REMOVE_START = 'DOCUMENT_LOAD_USER_REMOVE_START';
export const DOCUMENT_LOAD_USER_REMOVE_FINISH = 'DOCUMENT_LOAD_USER_REMOVE_FINISH';
export const DOCUMENT_LOAD_USER_REMOVE_ERROR = 'DOCUMENT_LOAD_USER_REMOVE_ERROR';

export const DOCUMENT_STASH_LOAD_START = 'DOCUMENT_STASH_LOAD_START';
export const DOCUMENT_STASH_LOAD_FINISH = 'DOCUMENT_STASH_LOAD_FINISH';
export const DOCUMENT_STASH_LOAD_ERROR = 'DOCUMENT_STASH_LOAD_ERROR';

export const DOCUMENT_STASH_DELETE_START = 'DOCUMENT_STASH_DELETE_START';
export const DOCUMENT_STASH_DELETE_FINISH = 'DOCUMENT_STASH_DELETE_FINISH';
export const DOCUMENT_STASH_DELETE_ERROR = 'DOCUMENT_STASH_DELETE_ERROR';

export const DOCUMENT_STASH_UPDATE_START = 'DOCUMENT_STASH_UPDATE_START';
export const DOCUMENT_STASH_UPDATE_FINISH = 'DOCUMENT_STASH_UPDATE_FINISH';
export const DOCUMENT_STASH_UPDATE_ERROR = 'DOCUMENT_STASH_UPDATE_ERROR';

export const DOCUMENTS_WORKFLOW_START = 'DOCUMENTS_WORKFLOW_START';
export const DOCUMENTS_WORKFLOW_FINISH = 'DOCUMENTS_WORKFLOW_FINISH';
export const DOCUMENTS_WORKFLOW_ERROR = 'DOCUMENTS_WORKFLOW_ERROR';

export const DOCUMENTS_EL_START_LOAD = 'DOCUMENTS_EL_START_LOAD';
export const DOCUMENTS_EL_FINISH_LOAD = 'DOCUMENTS_EL_FINISH_LOAD';
export const DOCUMENTS_EL_ERROR_LOAD = 'DOCUMENTS_EL_ERROR_LOAD';

export const PUSHES_ADD_START = 'PUSHES_ADD_START';
export const PUSHES_ADD_FINISH = 'PUSHES_ADD_FINISH';
export const PUSHES_ADD_ERROR = 'PUSHES_ADD_ERROR';


export const REGIONS_START_UPLOAD = 'REGIONS_START_UPLOAD';
export const REGIONS_FINISH_UPLOAD = 'REGIONS_FINISH_UPLOAD';
export const REGIONS_ERROR_UPLOAD = 'REGIONS_ERROR_UPLOAD';
