export const MEDIA_START_LOAD = 'MEDIA_START_LOAD';
export const MEDIA_FINISH_LOAD = 'MEDIA_FINISH_LOAD';
export const MEDIA_ERROR_LOAD = 'MEDIA_ERROR_LOAD';

export const MEDIA_UPDATE_START = 'MEDIA_UPDATE_START';
export const MEDIA_UPDATE_FINISH = 'MEDIA_UPDATE_FINISH';
export const MEDIA_UPDATE_ERROR = 'MEDIA_UPDATE_ERROR';

export const MEDIA_CREATE_START = 'MEDIA_CREATE_START';
export const MEDIA_CREATE_FINISH = 'MEDIA_CREATE_FINISH';
export const MEDIA_CREATE_ERROR = 'MEDIA_CREATE_ERROR';
