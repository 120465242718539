export const START_LOAD_PERMISSIONS = 'START_LOAD_PERMISSIONS';
export const ERROR_LOAD_PERMISSIONS = 'ERROR_LOAD_PERMISSIONS';
export const FINISH_LOAD_PERMISSIONS = 'FINISH_LOAD_PERMISSIONS';

export const START_CREATE_PERMISSION = 'START_CREATE_PERMISSION';
export const ERROR_CREATE_PERMISSION = 'ERROR_CREATE_PERMISSION';
export const FINISH_CREATE_PERMISSION = 'FINISH_CREATE_PERMISSION';

export const START_DELETE_PERMISSION = 'START_DELETE_PERMISSION';
export const ERROR_DELETE_PERMISSION = 'ERROR_DELETE_PERMISSION';
export const FINISH_DELETE_PERMISSION = 'FINISH_DELETE_PERMISSION';

export const START_UPDATE_PERMISSION = 'START_UPDATE_PERMISSION';
export const FINISH_UPDATE_PERMISSION = 'FINISH_UPDATE_PERMISSION';
export const ERROR_UPDATE_PERMISSION = 'ERROR_UPDATE_PERMISSION';
