import * as C from './constant';

const InitialState = {
    data: {}
};

export default (state = InitialState, action) => {
    if (action.type && C[action.type]) {
        return {
            state: action.type,
            data: action.data || {}
        };
    }
    return state;
}
