export const WIDGETS_LOAD_START = 'WIDGETS_LOAD_START';
export const WIDGETS_LOAD_FINISH = 'WIDGETS_LOAD_FINISH';
export const WIDGETS_LOAD_ERROR = 'WIDGETS_LOAD_ERROR';

export const WIDGET_ADD_START = 'WIDGET_ADD_START';
export const WIDGET_ADD_FINISH = 'WIDGET_ADD_FINISH';
export const WIDGET_ADD_ERROR = 'WIDGET_ADD_ERROR';

export const WIDGET_UPDATE_START = 'WIDGET_UPDATE_START';
export const WIDGET_UPDATE_FINISH = 'WIDGET_UPDATE_FINISH';
export const WIDGET_UPDATE_ERROR = 'WIDGET_UPDATE_ERROR';

export const WIDGET_DELETE_START = 'WIDGET_DELETE_START';
export const WIDGET_DELETE_FINISH = 'WIDGET_DELETE_FINISH';
export const WIDGET_DELETE_ERROR = 'WIDGET_DELETE_ERROR';

export const WIDGET_LOAD_START = 'WIDGET_LOAD_START';
export const WIDGET_LOAD_FINISH = 'WIDGET_LOAD_FINISH';
export const WIDGET_LOAD_ERROR = 'WIDGET_LOAD_ERROR';

export const WIDGET_DOCS_LOAD_START = 'WIDGET_DOCS_LOAD_START';
export const WIDGET_DOCS_LOAD_FINISH = 'WIDGET_DOCS_LOAD_FINISH';
export const WIDGET_DOCS_LOAD_ERROR = 'WIDGET_DOCS_LOAD_ERROR';

export const WIDGETS_REPORT_LOAD_START = 'WIDGETS_REPORT_LOAD_START';
export const WIDGETS_REPORT_LOAD_FINISH = 'WIDGETS_REPORT_LOAD_FINISH';
export const WIDGETS_REPORT_LOAD_ERROR = 'WIDGETS_REPORT_LOAD_ERROR';

export const WIDGETS_SORT_UPDATE_START = 'WIDGETS_SORT_UPDATE_START';
export const WIDGETS_SORT_UPDATE_FINISH = 'WIDGETS_SORT_UPDATE_FINISH';
export const WIDGETS_SORT_UPDATE_ERROR = 'WIDGETS_SORT_UPDATE_ERROR';

export const DOCUMENT_UPDATE_START = 'DOCUMENT_UPDATE_START';
export const DOCUMENT_UPDATE_FINISH = 'DOCUMENT_UPDATE_FINISH';
export const DOCUMENT_UPDATE_ERROR = 'DOCUMENT_UPDATE_ERROR';
