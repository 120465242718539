// return object property by name
export const GetProp = (obj = {}, prop = '') => {
    if (prop.indexOf('.') + 1 === 0) {
        return obj[prop];
    }
    let props = prop.split('.');
    if (obj.hasOwnProperty(props[0])) {
        return GetProp(obj[props[0]], props.splice(1, props.length).join('.'))
    }
    return undefined;
};

export default GetProp;
