export const PROFILES_LOAD_START = 'PROFILES_LOAD_START';
export const PROFILES_LOAD_FINISH = 'PROFILES_LOAD_FINISH';
export const PROFILES_LOAD_ERROR = 'PROFILES_LOAD_ERROR';

export const PROFILES_UPDATE_START = 'PROFILES_UPDATE_START';
export const PROFILES_UPDATE_FINISH = 'PROFILES_UPDATE_FINISH';
export const PROFILES_UPDATE_ERROR = 'PROFILES_UPDATE_ERROR';

export const PROFILE_LOAD_START = 'PROFILE_LOAD_START';
export const PROFILE_LOAD_FINISH = 'PROFILE_LOAD_FINISH';
export const PROFILE_LOAD_ERROR = 'PROFILE_LOAD_ERROR';


/*groups*/
export const GROUPS_LOAD_START = 'GROUPS_LOAD_START';
export const GROUPS_LOAD_FINISH = 'GROUPS_LOAD_FINISH';
export const GROUPS_LOAD_ERROR = 'GROUPS_LOAD_ERROR';

export const GROUPS_CREATE_START = 'GROUPS_CREATE_START';
export const GROUPS_CREATE_FINISH = 'GROUPS_CREATE_FINISH';
export const GROUPS_CREATE_ERROR = 'GROUPS_CREATE_ERROR';

export const GROUPS_UPDATE_START = 'GROUPS_UPDATE_START';
export const GROUPS_UPDATE_FINISH = 'GROUPS_UPDATE_FINISH';
export const GROUPS_UPDATE_ERROR = 'GROUPS_UPDATE_ERROR';

export const GROUPS_DELETE_START = 'GROUPS_DELETE_START';
export const GROUPS_DELETE_FINISH = 'GROUPS_DELETE_FINISH';
export const GROUPS_DELETE_ERROR = 'GROUPS_DELETE_ERROR';

/*permissions*/
export const PERMISSIONS_LOAD_START = 'PERMISSIONS_LOAD_START';
export const PERMISSIONS_LOAD_FINISH = 'PERMISSIONS_LOAD_FINISH';
export const PERMISSIONS_LOAD_ERROR = 'PERMISSIONS_LOAD_ERROR';

export const PERMISSIONS_CREATE_START = 'PERMISSIONS_CREATE_START';
export const PERMISSIONS_CREATE_FINISH = 'PERMISSIONS_CREATE_FINISH';
export const PERMISSIONS_CREATE_ERROR = 'PERMISSIONS_CREATE_ERROR';

export const PERMISSIONS_UPDATE_START = 'PERMISSIONS_UPDATE_START';
export const PERMISSIONS_UPDATE_FINISH = 'PERMISSIONS_UPDATE_FINISH';
export const PERMISSIONS_UPDATE_ERROR = 'PERMISSIONS_UPDATE_ERROR';

export const PERMISSIONS_DELETE_START = 'PERMISSIONS_DELETE_START';
export const PERMISSIONS_DELETE_FINISH = 'PERMISSIONS_DELETE_FINISH';
export const PERMISSIONS_DELETE_ERROR = 'PERMISSIONS_DELETE_ERROR';
