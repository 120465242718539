export function generateParamsFromObject(data = {}, name = false) {
    let params = [];
    for (let i in data) {
        let keyName = name === false ? `${i}` : `${name}[${i}]`;
        if (data[i] !== undefined) {
            if (typeof data[i] === "object") {
                params.push(generateParamsFromObject(data[i], i));
            } else {
                params.push(`${keyName}=${encodeURIComponent(data[i])}`);
            }
        }
    }
    return params.length > 0 ? params.join('&') : '';
}

export function parseUrlParams(request = '') {
    let url = request !== '' ? request.split('?')[1] : window.location.search.slice(1);
    // remove hash attrs
    url = url.split('#')[0];
    let result = {};

    if (url) {
        let url_array = url.split('&');
        for (let i = 0; i < url_array.length; i++) {
            let a = url_array[i].split('=');
            let param = a[0];
            let paramValue = typeof (a[1]) === 'undefined' ? true : a[1];
            // if find array
            if (param.match(/\[\d+\]$/)) {
                let key = param.replace(/\[(\d+)?\]/, '');
                if (!result[key]) result[key] = [];
                if (param.match(/\[\d+\]$/)) {
                    let index = /\[(\d+)\]/.exec(param)[1];
                    result[key][index] = decodeURIComponent(paramValue);
                } else {
                    result[key].push(decodeURIComponent(paramValue));
                }
            } else {
                if (!result[param]) {
                    result[param] = decodeURIComponent(paramValue);
                } else if (result[param] && typeof result[param] === 'string') {
                    result[param] = [decodeURIComponent(result[param])];
                    result[param].push(decodeURIComponent(paramValue));
                } else {
                    result[param].push(decodeURIComponent(paramValue));
                }
            }
        }
    }
    return result;
}


export function prepareParams(keys = []) {
    let params = {};
    let urlParams = parseUrlParams();
    keys.map(i => {
        if (urlParams[i] !== undefined) {
            params[i] = urlParams[i];
        }
        return i;
    });
    return params;
}
