import React from 'react';

export const RbthLayout = (p) => {
    return (
        <div className={`layout`}>
            RbthLayout layout
        </div>
    );
};

export default RbthLayout;
