import React from 'react';
import PropTypes from 'prop-types';
import * as Layouts from './layouts';

/*loading layout for applicaton*/
export const Layout = (p) => {
    let l = `${p.layout}_layout`;
    if (!Layouts[l]) {
        console.error(`App layout: ${l} not found, will use: default_layout`);
        l = `default_layout`;
    } else {
        console.log(`App layout loaded: `, l);
    }
    const LayoutComponent = Layouts[l];
    return <LayoutComponent {...p}/>;
};

Layout.defaultProps = {
    layout: 'default',
};

Layout.propTypes = {
    layout: PropTypes.string.isRequired,
};

export default Layout;
