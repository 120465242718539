export const GROUPS_LOAD_START = 'GROUPS_LOAD_START';
export const GROUPS_LOAD_ERROR = 'GROUPS_LOAD_ERROR';
export const GROUPS_LOAD_FINISH = 'GROUPS_LOAD_FINISH';

export const GROUPS_CREATE_START = 'GROUPS_CREATE_START';
export const GROUPS_CREATE_FINISH = 'GROUPS_CREATE_FINISH';
export const GROUPS_CREATE_ERROR = 'GROUPS_CREATE_ERROR';

export const GROUPS_DELETE_START = 'GROUPS_DELETE_START';
export const GROUPS_DELETE_FINISH = 'GROUPS_DELETE_FINISH';
export const GROUPS_DELETE_ERROR = 'GROUPS_DELETE_ERROR';

export const GROUPS_INFO_START = 'GROUPS_INFO_START';
export const GROUPS_INFO_FINISH = 'GROUPS_INFO_FINISH';
export const GROUPS_INFO_ERROR = 'GROUPS_INFO_ERROR';

export const GROUPS_UPDATE_START = 'GROUPS_UPDATE_START';
export const GROUPS_UPDATE_ERROR = 'GROUPS_UPDATE_ERROR';
export const GROUPS_UPDATE_FINISH = 'GROUPS_UPDATE_FINISH';

export const PERMISSIONS_START_LOAD = 'PERMISSIONS_START_LOAD';
export const PERMISSIONS_FINISH_LOAD = 'PERMISSIONS_FINISH_LOAD';
export const PERMISSIONS_ERROR_LOAD = 'PERMISSIONS_ERROR_LOAD';
