export const COMMENTS_LOAD_START = 'COMMENTS_LOAD_START';
export const COMMENTS_LOAD_ERROR = 'COMMENTS_LOAD_ERROR';
export const COMMENTS_LOAD_FINISH = 'COMMENTS_LOAD_FINISH';

export const COMMENTS_DELETE_START = 'COMMENTS_DELETE_START';
export const COMMENTS_DELETE_FINISH = 'COMMENTS_DELETE_FINISH';
export const COMMENTS_DELETE_ERROR = 'COMMENTS_DELETE_ERROR';

export const COMMENTS_UPDATE_START = 'COMMENTS_UPDATE_START';
export const COMMENTS_UPDATE_FININSH = 'COMMENTS_UPDATE_FININSH';
export const COMMENTS_UPDATE_ERROR = 'COMMENTS_UPDATE_ERROR';
