// sources for media
export default [
    'Free',
    'AFP',
    'РИА Новости',
    'RT',
    'Reuters',
    'Twitter',
    'Facebook',
    'kremlin.ru',
    'nasa.gov',
    'vk.com',
    'NASA',
    'globallookpress.com',
    'Gettyimages.ru',
    "Wikimedia.org",
    "TopTripTip",
    "Gateway to Russia",
    "Культура.РФ",
    "Вечерняя Москва",
    "Вечерка ТВ",
    "Ruptly",
    "IZ.ru"
]
