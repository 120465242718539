export const RMATERIAL_LOAD_START = 'RMATERIAL_LOAD_START';
export const RMATERIAL_LOAD_FINISH = 'RMATERIAL_LOAD_FINISH';
export const RMATERIAL_LOAD_ERROR = 'RMATERIAL_LOAD_ERROR';


export const REPORT_LIST_LOAD_START = 'REPORT_LOAD_START';
export const REPORT_LIST_LOAD_FINISH = 'REPORT_LOAD_FINISH';
export const REPORT_LIST_LOAD_ERROR = 'REPORT_LOAD_ERROR';

export const REPORT_UPLOAD_START = 'REPORT_UPLOAD_START';
export const REPORT_UPLOAD_FINISH = 'REPORT_UPLOAD_START';
export const REPORT_UPLOAD_ERROR = 'REPORT_UPLOAD_START';

export const REPORT_REMOVE_START = 'REPORT_REMOVE_START';
export const REPORT_REMOVE_FINISH = 'REPORT_REMOVE_FINISH';
export const REPORT_REMOVE_ERROR = 'REPORT_REMOVE_ERROR';

export const REPORT_LOAD_START = 'REPORT_LOAD_START';
export const REPORT_LOAD_FINISH = 'REPORT_LOAD_FINISH';
export const REPORT_LOAD_ERROR = 'REPORT_LOAD_ERROR';

export const VIDEO_LOAD_START = 'VIDEO_LOAD_START';
export const VIDEO_LOAD_FINISH = 'VIDEO_LOAD_FINISH';
export const VIDEO_LOAD_ERROR = 'VIDEO_LOAD_ERROR';
